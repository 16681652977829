import * as uuid from 'uuid';
import { sort } from '@/constants';
import {
  device_manufacturers_join,
  device_types_join,
  devicestatuses_join,
  vt_counterparties_join,
  // issuance_areas_join,
  // vt_counterparties_join
} from '@/joins';

export default {
  tableCode: 'devices',
  tableName: 'Оборудование',
  folderName: 'contragents',
  indexField: ['id', 'counterparty_id'],
  defaultOrder: {
    id: sort.ASC,
  },
  tableDescription: [
    {
      type: BigInt,
      name: 'Уникальный ID',
      key: 'id',
      // column_hidden: true,
      // skip_input: true,
    },
    {
      type: String,
      name: 'Серийный номер',
      key: 'serial_number',
    },
    device_types_join('device_type', 'Тип оборудования', {
      default_value: '1',
      enabled_if: () => false,
    }),
    vt_counterparties_join('counterparty_id'),
    {
      type: BigInt,
      name: 'Площадка выпуска',
      key: 'issuance_areas_id',
      relation: {
        table: 'issuance_areas_2',
        filter_by: 'counterparty_id',
        filter_by_in_join: 'id_carrier',
        join_rules: {
          issuance_areas_id: 'id',
          // counterparty_id: 'id_carrier',
        },
      },
    },
    // issuance_areas_join('issuance_areas_id', 'counterparty_id'),
    // {
    //     type: BigInt,
    //     name: 'Площадка выпуска',
    //     key: 'issuance_areas_id',
    //     relation: {
    //         table: 'issuance_areas_2',
    //         join_rules: {
    //             issuance_areas_id: 'id',
    //         }
    //     }
    // },
    device_manufacturers_join('id_manufacturer', 'Производитель'),
    {
      type: String,
      name: 'Модель',
      key: 'model',
    },
    {
      type: Date,
      name: 'Дата регистрации',
      key: 'reg_datetime',
    },
    {
      type: String,
      generated: true,
      generator: () => uuid.v4(),
      input_type: 'textarea',
      key: 'access_token',
      name: 'Токен авторизации',
    },
    {
      type: Date,
      key: 'token_expires',
      name: 'Срок действия токена',
    },
    {
      type: Date,
      name: 'Дата установки статуса',
      key: 'status_datetime',
      skip_input: true,
    },
    devicestatuses_join('status_id'),
  ],
};
