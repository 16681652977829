import Store from '@/store/store';
// import moment from "moment-timezone";

const viewField = function () {
  this.valueOf = () => ['id', 'name', 'job_title'];
};

export default {
  vpblifecyclehistory: {
    url: 'universal/vpblifecycle',
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=1.%20Отчет%20История%20жизненного%20цикла%20ВПБ.frx',
    name: 'Отчет «История жизненного цикла ВПБ»',
    filters: [
      {
        key: 'card_number',
        type: BigInt,
        name: 'Номер виртуального носителя',
        doubleLine: true,
        enabled_if: (filters) => !filters.card_id && !filters.phone_number,
      },
      {
        key: 'card_id',
        type: String,
        name: 'Номер ВПБ',
        enabled_if: (filters) => !filters.card_number && !filters.phone_number,
      },
      {
        key: 'phone_number',
        type: String,
        name: 'Телефон пользователя',
        enabled_if: (filters) => !filters.card_number && !filters.card_id,
      },
      {
        key: 'date',
        type: Date,
        name: 'Выбор даты',
      },
      {
        key: 'EventType',
        type: Array,
        name: 'Тип события',
        values: () => [
          {
            value: 'Реализация (выпуск)',
            name: 'Реализация (выпуск)',
          },
          {
            value: 'Реализация',
            name: 'Реализация',
          },
          {
            value: 'Гашение',
            name: 'Гашение',
          },
          {
            value: 'Контроль',
            name: 'Контроль',
          },
          {
            value: 'Блокировка',
            name: 'Блокировка',
          },
        ],
      },
    ],
    validate: (filters) => {
      if (
        filters.card_id &&
        filters.card_id.trim() !== '' &&
        /^(\d+)-(\d+)\/(\d)$/.test(filters.card_id) === false
      ) {
        throw 'Номер впб должен быть вида 11111-123/4';
      }
    },
  },
  transactionvpb: {
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=2.%20Отчет%20Реестр%20сверки%20для%20Перевозчика%20за%20период.frx',
    name: 'Отчет «Реестр сверки для Перевозчика за период»',
    url: 'universal/transactionvpb',
    async init() {
      await Promise.all([
        Store.$store.dispatch('unitable/fetchSourceForSelect', {
          table_name: 'vt_counterparties_realisators',
          primaryKey: ['counterparty_id'],
          viewField: 'counterparty_shortname',
        }),
        Store.$store.dispatch('unitable/fetchSourceForSelect', {
          table_name: 'vt_routesdata',
          primaryKey: ['id'],
          viewField: 'route_name',
        }),
      ]);
    },
    filters: [
      {
        key: 'operationdatetime',
        type: Date,
        name: 'Выбор даты',
      },
      {
        name: 'Перевозчик',
        type: Array,
        key: 'idcarrier',
        send_name: 'idcarriername',
        values: () =>
          Store?.$store?.getters['unitable/getTable'](
            'vt_counterparties_realisators'
          )?.map((item) => ({
            value: item.counterparty_id,
            name: item.counterparty_shortname,
          })),
      },
      {
        name: 'Тип ПБ',
        type: BigInt,
        key: 'tickettype',
      },
      {
        name: 'Вид ПБ',
        type: BigInt,
        key: 'ticketsubtype',
      },
      {
        name: 'Маршрут (номер)',
        type: Array,
        key: 'idroute',
        placeholder: 'Введите номер маршрута',
        values: () =>
          Store?.$store?.getters['unitable/getTable']('vt_routesdata')?.map(
            (item) => ({
              value: item.id,
              name: item.route_name,
            })
          ),
      },

      {
        name: 'Бортовой номер',
        type: BigInt,
        key: 'idts',
      },
    ],
  },
  sodtrans: {
    name: 'Отчет «Реестр сверки для Контрагента по реализации за период»',
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=3.%20Отчет%20Реестр%20сверки%20для%20Контрагента%20по%20реализации%20за%20период.frx',
    url: 'universal/sodtrans',
    async init() {
      await Store.$store.dispatch('unitable/fetchSourceForSelect', {
        table_name: 'vt_counterparties_carriers',
        primaryKey: ['counterparty_id'],
        viewField: 'counterparty_shortname',
      });
    },
    filters: [
      {
        key: 'reg_date_time',
        type: Date,
        name: 'Выбор даты',
      },
      {
        name: 'Контрагент',
        type: Array,
        key: 'vpbagentid',
        send_name: 'vpbagentname',
        values: () =>
          Store?.$store?.getters['unitable/getTable'](
            'vt_counterparties_carriers'
          )?.map((item) => ({
            value: item.counterparty_id,
            name: item.counterparty_shortname,
          })),
      },
      {
        name: 'Тип ПБ',
        type: BigInt,
        key: 'card_type',
      },
      {
        name: 'Вид ПБ',
        type: BigInt,
        key: 'card_sub_type',
      },
    ],
  },
  sodtranslong: {
    index: 4,
    ownKey: 'sodtranslong',
    name: 'Отчет о реализации виртуальных проездных билетов длительного пользования (льготные ПБ)',
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=4. Отчет о реализации виртуальных проездных билетов длительного пользования (льготные ПБ).frx',
    url: 'universal/sodtranslong',
    filters: [
      {
        key: 'begin_date_time',
        type: Date,
        name: 'Выбор даты',
      },
    ],
  },
  sodtranslong2: {
    index: 4,
    ownKey: 'sodtranslong',
    name: 'Отчет о реализации виртуальных проездных билетов длительного пользования (нельготные ПБ)',
    customIframeUrl: `${window.location.origin}/Reports/Home/Update?SelectedItem=4. Отчет о реализации виртуальных проездных билетов длительного пользования (не льготные ПБ).frx`,
    url: 'universal/sodtranslong2',
    filters: [
      {
        key: 'begin_date_time',
        type: Date,
        name: 'Выбор даты',
      },
    ],
  },
  registeredtransactions: {
    name: 'Отчет Перевозчика о зарегистрированных транзакциях по видам проездных билетов ',
    url: 'universal/registeredtransactions',
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=5.%20Отчет%20Перевозчика%20о%20зарегистрированных%20транзакциях%20по%20видам%20проездных%20билетов.frx',
    async init() {
      await Promise.all([
        Store.$store.dispatch('unitable/fetchSourceForSelect', {
          table_name: 'vt_counterparties_realisators',
          primaryKey: ['counterparty_id'],
          viewField: 'counterparty_shortname',
        }),
      ]);
    },
    filters: [
      {
        key: 'operationdatetime',
        type: Date,
        name: 'Выбор даты',
      },
      {
        name: 'Перевозчик',
        type: Array,
        key: 'idcarrier',
        send_name: 'idcarriername',
        values: () =>
          Store?.$store?.getters['unitable/getTable'](
            'vt_counterparties_realisators'
          )?.map((item) => ({
            value: item.counterparty_id,
            name: item.counterparty_shortname,
          })),
      },
    ],
    validate: (filters) => {
      if (
        !filters.idcarrier ||
        !filters.operationdatetime ||
        !filters.operationdatetime[0] ||
        !filters.operationdatetime[1]
      ) {
        throw 'Укажите все поля';
      }
    },
  },
  sodtransrealisation: {
    name: 'Отчет «Взаиморасчеты по реализации»',
    url: 'universal/sodtransrealisation',
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=6.%20Отчет%20Взаиморасчеты%20по%20реализации.frx',
    filters: [
      {
        key: 'reg_date_time',
        type: Date,
        name: 'Выбор даты',
      },
    ],
    validate: (filters) => {
      if (
        !filters.reg_date_time ||
        !filters.reg_date_time[0] ||
        !filters.reg_date_time[1]
      ) {
        throw 'Укажите время';
      }
    },
  },
  event_types: {
    name: 'Отчет по типам событий за период',
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=7.%20Отчет%20по%20типам%20событий%20за%20период.frx',
    url: 'universal/eventtypes',
    filters: [
      {
        key: 'date',
        type: Date,
        name: 'Выбор даты',
      },
      {
        key: 'EventType',
        type: Array,
        name: 'Тип события',
        values: () => [
          {
            value: 'Регистрация (выпуск)',
            name: 'Регистрация (выпуск)',
          },
          {
            value: 'Реализация',
            name: 'Реализация',
          },
          {
            value: 'Гашение',
            name: 'Гашение',
          },
          {
            value: 'Контроль гашения',
            name: 'Контроль гашения',
          },
          {
            value: 'Восстановление',
            name: 'Восстановление',
          },
          {
            value: 'Блокировка',
            name: 'Блокировка',
          },
          {
            value: 'Разблокировка',
            name: 'Разблокировка',
          },
        ],
      },
    ],
    validate: (filters) => {
      if (!filters.date || !filters.date[0] || !filters.date[1]) {
        throw 'Укажите время';
      }
    },
  },
  transactions_correct: {
    url: 'universal/transactionscorrected',
    name: 'Отчет «Анализ корректности гашения датаграмм для ВПБ»',
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=8.%20Отчет%20Анализ%20корректности%20гашения%20датаграмм%20для%20ВПБ.frx',
    filters: [
      {
        name: 'Номер билета',
        key: 'cardid',
        type: BigInt,
      },
      {
        key: 'createdatetime',
        type: Date,
        name: 'Выбор даты',
      },
    ],
    validate: (filters) => {
      if (!filters.cardid) {
        throw 'Укажите номер билета';
      }
    },
  },
  transactions_sodtrans: {
    url: 'universal/transactionssodtrans',
    name: 'Отчет по получению детализации по ВПБ',
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=9.%20Отчет%20по%20получению%20детализации%20по%20ВПБ.frx',
    filters: [
      {
        name: 'Номер билета',
        key: 'cardid',
        type: BigInt,
      },
      {
        key: 'date',
        type: Date,
        name: 'Выбор даты',
      },
    ],
    validate: (filters) => {
      if (!filters.cardid) {
        throw 'Укажите номер билета';
      }
    },
  },
  _15m: {
    url: 'universal/15m',
    index: 15,
    name: 'Отчет о распределении выручки от реализации единых проездных билетов ',
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=15.%20Отчет%20о%20распределении%20выручки%20от%20реализации%20единых%20проездных%20билетов.frx',
    filters: [
      {
        key: 'operationdatetime',
        type: Date,
        name: 'Выбор даты',
      },
    ],
    validate: (filters) => {
      if (
        !filters.operationdatetime ||
        !filters.operationdatetime[0] ||
        !filters.operationdatetime[1]
      ) {
        throw 'Укажите время';
      }
    },
    async init() {
      await Store.$store.dispatch('unitable/fetchSourceForSelect', {
        table_name: 'vt_signatory_l',
        primaryKey: ['id'],
        viewField,
      });
    },
    signersOn: true,
    responsibleOn: true,
  },
  _16m: {
    url: 'universal/16m',
    index: 16,
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=16.%20Отчет%20о%20распределении%20выручки%20от%20реализации%20проездных%20билетов%20повременных.frx',
    name: 'Отчет о распределении выручки от реализации проездных билетов повременных',
    filters: [
      {
        key: 'operationdatetime',
        type: Date,
        name: 'Выбор даты',
      },
    ],
    validate: (filters) => {
      if (
        !filters.operationdatetime ||
        !filters.operationdatetime[0] ||
        !filters.operationdatetime[1]
      ) {
        throw 'Укажите время';
      }
    },
    async init() {
      await Store.$store.dispatch('unitable/fetchSourceForSelect', {
        table_name: 'vt_signatory_l',
        primaryKey: ['id'],
        viewField,
      });
    },
    signersOn: true,
    responsibleOn: true,
  },
  _17m: {
    url: 'universal/17m',
    index: 17,
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=17.%20Отчет%20о%20распределении%20выручки%20от%20реализации%20ЕЭБ.frx',
    name: 'Отчет о распределении выручки от реализации ЕЭБ',
    filters: [
      {
        key: 'operationdatetime',
        type: Date,
        name: 'Выбор даты',
      },
    ],
    validate: (filters) => {
      if (
        !filters.operationdatetime ||
        !filters.operationdatetime[0] ||
        !filters.operationdatetime[1]
      ) {
        throw 'Укажите время';
      }
    },
    async init() {
      await Store.$store.dispatch('unitable/fetchSourceForSelect', {
        table_name: 'vt_signatory_l',
        primaryKey: ['id'],
        viewField,
      });
    },
    signersOn: true,
    responsibleOn: true,
  },
  _18m: {
    url: 'universal/18m',
    index: 18,
    name: 'Отчет о распределении выручки от реализации проездных билетов на наземные виды транспорта',
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=18.%20Отчет%20о%20распределении%20выручки%20от%20реализации%20проездных%20билетов%20на%20наземные%20виды%20транспорта.frx',
    filters: [
      {
        key: 'operationdatetime',
        type: Date,
        name: 'Выбор даты',
      },
    ],
    validate: (filters) => {
      if (
        !filters.operationdatetime ||
        !filters.operationdatetime[0] ||
        !filters.operationdatetime[1]
      ) {
        throw 'Укажите время';
      }
    },
    async init() {
      await Store.$store.dispatch('unitable/fetchSourceForSelect', {
        table_name: 'vt_signatory_l',
        primaryKey: ['id'],
        viewField,
      });
    },
    signersOn: true,
    responsibleOn: true,
  },
  _19m: {
    url: 'universal/report19m',
    index: 19,
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=19.%20Отчет%20об%20активированных%20проездных%20билетах%20и%20транзакциях,%20совершенных%20пассажирами%20по%20проездным%20билетам.frx',
    name: 'Отчет об активированных проездных билетах и транзакциях, совершенных пассажирами по проездным билетам по данным автоматизированного учета',
    async init() {
      await Promise.all([
        Store.$store.dispatch('unitable/fetchSourceForSelect', {
          table_name: 'vt_counterparties_carriers',
          primaryKey: ['counterparty_id'],
          viewField: 'counterparty_shortname',
        }),
        Store.$store.dispatch('unitable/fetchSourceForSelect', {
          table_name: 'vt_signatory_l',
          primaryKey: ['id'],
          viewField,
        }),
      ]);
    },
    signersOn: true,
    filters: [
      {
        key: 'operationdatetime',
        type: Date,
        name: 'Выбор даты',
      },
      {
        name: 'Перевозчик',
        type: Array,
        key: 'agentid',
        values: () =>
          Store?.$store?.getters['unitable/getTable'](
            'vt_counterparties_carriers'
          )?.map((item) => ({
            value: item.counterparty_id,
            name: item.counterparty_shortname,
          })),
      },
    ],
    validate: (filters) => {
      if (
        !filters.operationdatetime ||
        !filters.operationdatetime[0] ||
        !filters.operationdatetime[1]
      ) {
        throw 'Укажите время';
      }
    },
  },
  _20m: {
    url: 'universal/20m',
    index: 20,
    customIframeUrl:
      '/Reports/Home/Update?SelectedItem=20.%20Отчет%20о%20транзакциях,%20совершенных%20пассажирами%20по%20проездным%20билетам.frx',
    name: 'Отчет о транзакциях, совершенных пассажирами по проездным билетам по данным автоматизированного учета',
    filters: [
      {
        key: 'operationdatetime',
        type: Date,
        name: 'Выбор даты',
      },
    ],
    validate: (filters) => {
      if (
        !filters.operationdatetime ||
        !filters.operationdatetime[0] ||
        !filters.operationdatetime[1]
      ) {
        throw 'Укажите время';
      }
    },
    async init() {
      await Store.$store.dispatch('unitable/fetchSourceForSelect', {
        table_name: 'vt_signatory_l',
        primaryKey: ['id'],
        viewField,
      });
    },
    signersOn: true,
  },
  // reconciliation: {
  //     url: "/reconciliation",
  //     name: 'Сверка для Перевозчика',
  //     upload: true,
  //     uploadUrl: 'reports/check-reconcillation',
  //     async init() {
  //         await Promise.all([
  //             Store.$store.dispatch('unitable/fetchSourceForSelect', {
  //                 table_name: 'vt_counterparties_realisators',
  //                 primaryKey: ['counterparty_id'],
  //                 viewField: 'counterparty_shortname'
  //             })
  //         ]);
  //     },
  //     filters: [
  //         {
  //             key: 'operationdatetime',
  //             type: Date,
  //             name: 'Выбор даты',
  //             decorator: value => {
  //                 console.log(moment(value).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss'));
  //                 return `decorated:${moment(value).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss')}`
  //             }
  //         },
  //         {
  //             name: 'Перевозчик',
  //             type: Array,
  //             key: 'idcarrier',
  //             values: () => Store?.$store?.getters['unitable/getTable']('vt_counterparties_realisators')?.map(item => ({
  //                 value: item.counterparty_id,
  //                 name: item.counterparty_shortname
  //             }))
  //         },
  //     ],
  //     validate: (filters) => {
  //         if (!filters.idcarrier || !filters.operationdatetime || !filters.operationdatetime[0] || !filters.operationdatetime[1]) {
  //             throw 'Укажите все поля'
  //         }
  //     },
  // },
  // sodtrans_reconciliation: {
  //     name: 'Сверка с контрагентом по реализации',
  //     url: '/sodtrans_reconciliation',
  //     upload: true,
  //     uploadUrl: 'reports/check-sodtrans-reconcillation',
  //     async init() {
  //         await Store.$store.dispatch('unitable/fetchSourceForSelect', {
  //             table_name: 'vt_counterparties_carriers',
  //             primaryKey: ['counterparty_id'],
  //             viewField: 'counterparty_shortname'
  //         })
  //     },
  //     filters: [
  //         {
  //             key: 'reg_date_time',
  //             type: Date,
  //             name: 'Выбор даты',
  //             decorator: value => {
  //                 console.log(moment(value).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss'));
  //                 return `decorated:${moment(value).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss')}`
  //             }
  //         },
  //         {
  //             name: 'Контрагент',
  //             type: Array,
  //             key: 'vpbagentid',
  //             values: () => Store?.$store?.getters['unitable/getTable']('vt_counterparties_carriers')?.map(item => ({
  //                 value: item.counterparty_id,
  //                 name: item.counterparty_shortname
  //             }))
  //         },
  //     ],
  //     validate: (filters) => {
  //         if (!filters.vpbagentid || !filters.reg_date_time || !filters.reg_date_time[0] || !filters.reg_date_time[1]) {
  //             throw 'Укажите все поля'
  //         }
  //     },
  // },
};
