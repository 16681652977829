import {sort} from "@/constants";

export default {
    tableCode: 'vt_security_samupdates',
    tableName: 'Реестр обновлений <br>Ключевых ячеек SAM',
    isDoubleLine: true,
    indexField: ['samupdate_id'],
    folderName: 'key_security',
    defaultOrder: {
        samupdate_id: sort.ASC,
    },
    tableDescription: [

        {
            key: "samupdate_id",
            name: "ID",
            type: BigInt,
            relation: {
                table: 'vt_security_sam',
                join_rules: {
                    samupdate_id: 'sam_id'
                }
            }
        },
        {
            key: "fk_samupdate_sam_id",
            name: "SAM",
            type: BigInt,
            relation: {
                table: 'vt_security_samkeyentry',
                join_rules: {
                    fk_samupdate_sam_id: 'samkeyentry_id',
                }
            }
        },
        {
            key: "fk_samupdate_keyentry_id",
            name: "ID ключевой ячейки",
            type: BigInt
        },
        {
            key: "samupdate_create_date",
            name: "Дата-время создания записи",
            type: Date
        },
        {
            key: "samupdate_execute_date",
            name: "Дата-время выполнения обновления на SAM",
            type: Date
        },
        {
            key: "samupdate_offlinecommand",
            name: "Байтовая последовательность команды обновления SAM оффлайн",
            type: String,
            width: 300,
            // show_part: true,
            wrapper: (str) => {
                console.log(str);
                return JSON.stringify(str);
            }
        },
        {
            key: "samupdate_execute_sw",
            name: "Статус SW от SAM на выполнение команды обновления",
            type: String
        },
        {
            key: "samupdate_ordernumber",
            name: "Порядковый номер выполнения команды",
            type: BigInt
        },


    ],
};
