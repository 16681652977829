import { sort } from '@/constants';

export default {
  tableCode: 'vt_security_sam',
  tableName: 'SAM-модули',
  folderName: 'key_security',
  readOnly: true,
  importForceEnabled: true,
  indexField: ['sam_id'],
  defaultOrder: {
    sam_id: sort.ASC,
  },
  viewField: 'sam_uid',
  tableDescription: [
    {
      key: 'sam_id',
      name: 'ID',
      type: BigInt,
    },
    {
      key: 'sam_uid',
      name: 'UID SAM модуля',
      type: String,
    },
    {
      key: 'fk_sam_counterparty_id',
      name: 'Контрагент',
      type: BigInt,
      relation: {
        table: 'vt_counterparties',
        join_rules: {
          fk_sam_counterparty_id: 'counterparty_id',
        },
      },
    },
    {
      key: 'sam_activeflag',
      name: 'Допуск к эксплуатации',
      type: Boolean,
    },
    {
      key: 'sam_update_flag',
      name: 'Метка обновления',
      type: Boolean,
    },
    {
      key: 'fk_sam_kob_keyentry_id',
      name: 'ID ячейки с ключом КОБ',
      type: BigInt,
      relation: {
        table: '',
        join_rules: {
          fk_sam_kob_keyentry_id: 'samkeyentry_id',
        },
      },
    },
    {
      key: 'fk_sam_kob_key_id',
      name: 'ID ключа КОБ',
      type: BigInt,
      relation: {
        table: 'vt_security_keys',
        join_rules: {
          fk_sam_kob_key_id: 'key_id',
        },
      },
    },
    {
      key: 'fk_sam_kpd_keyentry_id',
      name: 'ID ячейки с ключом КПД',
      type: BigInt,
      relation: {
        table: 'vt_security_samkeyentry',
        join_rules: {
          fk_sam_kpd_keyentry_id: 'samkeyentry_id',
        },
      },
    },
    {
      key: 'fk_sam_kpk_keyentry_id',
      name: 'ID ячейки с ключом КПК',
      type: BigInt,
      relation: {
        table: 'vt_security_samkeyentry',
        join_rules: {
          ['fk_sam_kpk_keyentry_id']: 'samkeyentry_id',
        },
      },
    },
    {
      key: 'fk_sam_config_id',
      name: 'ID конфигурации',
      type: BigInt,
    },
    {
      key: 'fk_sam_kpd_keya_key_id',
      name: 'ID ключа в ячейке КПД / версия А',
      type: BigInt,
      relation: {
        table: 'vt_security_keys',
        join_rules: {
          ['fk_sam_kpd_keya_key_id']: 'key_id',
        },
      },
    },
    {
      key: 'fk_sam_kpd_keyb_key_id',
      name: 'ID ключа в ячейке КПД / версия В',
      type: BigInt,
      relation: {
        table: 'vt_security_keys',
        join_rules: {
          ['fk_sam_kpd_keyb_key_id']: 'key_id',
        },
      },
    },
    {
      key: 'fk_sam_kpd_keyc_key_id',
      name: 'ID ключа в ячейке КПД / версия С',
      type: BigInt,
      relation: {
        table: 'vt_security_keys',
        join_rules: {
          ['fk_sam_kpd_keyc_key_id']: 'key_id',
        },
      },
    },
    {
      key: 'fk_sam_kpk_keya_key_id',
      name: 'ID ключа в ячейке КПК / версия А',
      type: BigInt,
      relation: {
        table: 'vt_security_keys',
        join_rules: {
          ['fk_sam_kpk_keya_key_id']: 'key_id',
        },
      },
    },
    {
      key: 'fk_sam_kpk_keyb_key_id',
      name: 'ID ключа в ячейке КПК / версия В',
      type: BigInt,
      relation: {
        table: 'vt_security_keys',
        join_rules: {
          ['fk_sam_kpk_keyb_key_id']: 'key_id',
        },
      },
    },
    {
      key: 'fk_sam_kpk_keyc_key_id',
      name: 'ID ключа в ячейке КПК / версия С',
      type: BigInt,
      relation: {
        table: 'vt_security_keys',
        join_rules: {
          ['fk_sam_kpk_keyc_key_id']: 'key_id',
        },
      },
    },
  ],
};

/*

 {
            key: '',
            name: '',
            type: BigInt,
            relation: {
                table: '',
                join_rules: {
                    ['']: ''
                }
            }
        }

 */
