import sortTransform from '@/utils/sortTransform';
import BonusTypes from '@/tables/VPBNSIDatabase/BonusTypes';
import MutualPaymentsTypes from '@/tables/VPBNSIDatabase/MutualPaymentsTypes';
import Blacklist from '@/tables/Blacklist';
import CardsData from '@/tables/CardsData';
import CarrierOffices from '@/tables/CarrierOffices';
import CarriersData from '@/tables/CarriersData';
import CounterpartyTypes from '@/tables/CounterpartyTypes';
import Devices from '@/tables/COTNSIDatabase/Devices';
import EXTSALES from '@/tables/EXTSALES';
import Issuance_Areas from '@/tables/VPBDatabase/Issuance_Areas';
import MetroStations from '@/tables/MetroStations';
import RouteTarifTypes from '@/tables/VPBNSIDatabase/RouteTarifTypes';
import RouteTypes from '@/tables/COTNSIDatabase/RouteTypes';
import RoutesData from '@/tables/COTNSIDatabase/RoutesData';
import Tariffs from '@/tables/Tariffs';
import TransportCustomers from '@/tables/TransportCustomers';
import TransportData from '@/tables/COTNSIDatabase/TransportData';
import vt_transporttyps from '@/tables/COTNSIDatabase/vt_transporttyps';
import VT_Contracts from '@/tables/VPBDatabase/VT_Contracts';
import VT_Counterparties from '@/tables/COTNSIDatabase/VT_Counterparties';
import VT_Extra_charges from '@/tables/VPBDatabase/VT_Extra_charges';
import VT_Transfer from '@/tables/COTNSIDatabase/VT_Transfer';
import VT_Zone from '@/tables/COTNSIDatabase/VT_Zone';
import VT_bonuses from '@/tables/VT_bonuses';
import ZoneTariffs from '@/tables/ZoneTariffs';
import ZoneTariffsDetais from '@/tables/ZoneTariffsDetais';
import arc_cardsdata from '@/tables/arc_cardsdata';
import arc_routesdata from '@/tables/arc_routesdata';
import blacklistdelta from '@/tables/blacklistdelta';
import blacklistfull from '@/tables/blacklistfull';
import blacklistshort from '@/tables/blacklistshort';
import datagramm from '@/tables/reestr/datagramm';
import history from '@/tables/history';
import ordersdata from '@/tables/ordersdata';
import protocol_bocot_ext from '@/tables/protocol_bocot_ext';
import tarif from '@/tables/tarif';
import ticketinterval from '@/tables/reestr/ticketinterval';
import ticketmony from '@/tables/reestr/ticketmony';
import virtualcards from '@/tables/reestr/virtualcards';
import RoutesByContract from '@/tables/RoutesByContract';
import VT_RoutesByTransfer from '@/tables/VT_RoutesByTransfer';
import VT_RoutesByZone from '@/tables/VT_RoutesByZone';
import vt_typesbycontract from '@/tables/VT_TypesByContract';
import settings from '@/tables/settings';
import tarifclass from '@/tables/tarifclass';
import transactionsvpb from '@/tables/reestr/transactionsvpb';
// import message_types from "@/tables/COTNSIDatabase/message_types"
import system_users from '@/tables/UsersDatabase/system_users';
import counterparties_users_roles from '@/tables/counterparties_users_roles';
import counterparties_users_dictionaries from '@/tables/counterparties_users_dictionaries';
import dictionaries_list from '@/tables/dictionaries_list';
import vt_counterparties_carriers from '@/tables/vt_counterparties_carriers';
import devicestatuses from '@/tables/devicestatuses';
import issuance_areastatuses from '@/tables/issuance_areastatuses';
import vt_cardsdata from '@/tables/COTNSIDatabase/vt_cardsdata';
import table_permission_description from '@/tables/UsersDatabase/table_permission_description';
import system_roles from '@/tables/UsersDatabase/system_roles';
import vt_security_data from '@/tables/COTNSIDatabase/vt_security_data';
import device_types from '@/tables/VPBNSIDatabase/device_types';
import contract_types from '@/tables/VPBNSIDatabase/contract_types';
import device_manufacturers from '@/tables/VPBNSIDatabase/device_manufacturers';
import contract_kinds from '@/tables/VPBNSIDatabase/contract_kinds';
import exch_mutualpaymentstypes from '@/tables/VPBNSIDatabase/exch_mutualpaymentstypes';
import vt_security_algoritms from '@/tables/VPBNSIDatabase/vt_security_algoritms';
import counterpartyroles from '@/tables/VPBNSIDatabase/counterpartyroles';
import action_history from '@/tables/UsersDatabase/action_history';
import additional_messages from '@/tables/COTNSIDatabase/additional_messages';
import ticketunit from '@/tables/reestr/ticketunit';
import counterparties_statuses from '@/tables/VPBNSIDatabase/counterparties_statuses';
import sodtrans from '@/tables/reestr/sodtrans';
import controlvpb from '@/tables/reestr/controlvpb';
import logdata from '@/tables/reestr/logdata';
import vt_blacklist from '@/tables/reestr/vt_blacklist';
import vt_datagrammblacklist from '@/tables/reestr/vt_datagrammblacklist';
import testvirtualticket from '@/tables/reestr/testvpb/testvirtualticket';
import access_data from '@/tables/reestr/access_data';
import Issuance_Areas_2 from '@/tables/VPBDatabase/Issuance_Areas_2';
import contracts_statuses from '@/tables/VPBDatabase/contracts_statuses';
import vt_additional_agreement from '@/tables/VPBDatabase/vt_additional_agreement';
import vt_section from '@/tables/VPBDatabase/vt_section';
import vt_errorcode from '@/tables/vt_errorcode';
import vt_ticketstatus from '@/tables/vt_ticketstatus';
import reasonsdata from '@/tables/reasonsdata';
import cardcategories from '@/tables/cardcategories';
import tarifrules from '@/tables/tarifrules';
import vt_datagrammstatus from '@/tables/vt_datagrammstatus';
import datagrammtypes from '@/tables/reestr/datagrammtypes';
import carddatatypes from '@/tables/CardDataTypes';
import access_tocken_history from '@/tables/COTNSIDatabase/access_token_history';
import units_list from '@/tables/VPBDatabase/units_list';
import vt_section_priority from '@/tables/VPBDatabase/vt_section_priority';
import vt_cardsdata_for_select from '@/tables/COTNSIDatabase/vt_cardsdata_for_select';
import table_permission_description_readonly from '@/tables/UsersDatabase/table_permission_description_readonly';
import devices_access_dictionary from '@/tables/UsersDatabase/devices_access_dictionary';
import devices_access_statuses from '@/tables/UsersDatabase/devices_access_statuses';
import prioritydata from '@/tables/prioritydata';
import deviceidhistory from '@/tables/reestr/deviceidhistory';
import deviceidhistory_reasons from '@/tables/reestr/deviceidhistory_reasons';
import tariffplanticket60 from '@/tables/COTNSIDatabase/tariffplanticket60';
import lcl_closuretypes from '@/tables/reestr/transactionvpb/lcl_closuretypes';
import transactionsvpbtest from '@/tables/reestr/testvpb/transactionsvpbtest';
import sodtranstest from '@/tables/reestr/testvpb/sodtranstest';
import vt_datagrammblacklistreasons from '@/tables/reestr/vt_datagrammblacklistreasons';
import addinfo_fieldstypes from '@/tables/addinfo_fieldstypes';
import addinfo_fields from '@/tables/addinfo_fields';
import zoneroutetariffs from '@/tables/zones/zoneroutetariffs';
import zoneroute from '@/tables/zones/zoneroute';
import ReportsList from '@/tables/UsersDatabase/ReportsList';
import vt_cardsdata_for_blacklist from '@/tables/COTNSIDatabase/vt_cardsdata_for_blacklist';
import reparereseeb from '@/tables/reports/reparereseeb';
import zones from '@/tables/zones/zones';
import zone_stations from '@/tables/zones/zone_stations';
import zone_tariff from '@/tables/zones/zone_tariff';
import vt_counterparties_realisators from '@/tables/vt_counterparties_realisators';
// import vt_security_data_view from "@/tables/VPBNSIDatabase/vt_security_data_view";
import datagrammreasonsdata from '@/tables/reestr/datagrammreasonsdata';
// import vt_security_rel_key_sam_view from "@/tables/COTNSIDatabase/vt_security_rel_key_sam_view";
import vt_signatory_l from '@/tables/reports/vt_signatory_l';
import vt_security_keys from '@/tables/security/vt_security_keys';
import vt_security_sam from '@/tables/security/vt_security_sam';
import vt_security_samkeyentry from '@/tables/security/vt_security_samkeyentry';
import vt_security_keycategories from '@/tables/security/vt_security_keycategories';
import vt_security_samupdates from '@/tables/security/vt_security_samupdates';
import vt_security_settings from '@/tables/security/vt_security_settings';

const sortDecorator = (table) => {
  if (!table.defaultOrder) return table;

  if (Array.isArray(table.defaultOrder)) return table;

  const defaultOrder = sortTransform(table.defaultOrder);

  return {
    ...table,
    defaultOrder,
  };
};

const tables = {
  // BO_types: () => sortDecorator(BO_types),
  Blacklist: () => sortDecorator(Blacklist),
  units_list: () => sortDecorator(units_list),
  vt_section_priority: () => sortDecorator(vt_section_priority),
  reports_list: () => sortDecorator(ReportsList),
  vt_signatory_l: () => sortDecorator(vt_signatory_l),
  vt_cardsdata_for_blacklist: () => sortDecorator(vt_cardsdata_for_blacklist),
  vt_cardsdata_for_select: () => sortDecorator(vt_cardsdata_for_select),
  access_tocken_history_enriched: () => sortDecorator(access_tocken_history),
  vt_datagrammstatus: () => sortDecorator(vt_datagrammstatus),
  tarifrules: () => sortDecorator(tarifrules),
  reasonsdata: () => sortDecorator(reasonsdata),
  cardcategories: () => sortDecorator(cardcategories),
  BonusTypes: () => sortDecorator(BonusTypes),
  CardsData: () => sortDecorator(CardsData),
  CarrierOffices: () => sortDecorator(CarrierOffices),
  CarriersData: () => sortDecorator(CarriersData),
  CounterpartyTypes: () => sortDecorator(CounterpartyTypes),
  Devices: () => sortDecorator(Devices),
  EXTSALES: () => sortDecorator(EXTSALES),
  Issuance_Areas: () => sortDecorator(Issuance_Areas),
  Issuance_Areas_2: () => sortDecorator(Issuance_Areas_2),
  tariffplanticket60: () => sortDecorator(tariffplanticket60),
  MetroStations: () => sortDecorator(MetroStations),
  MutualPaymentsTypes: () => sortDecorator(MutualPaymentsTypes),
  RouteTarifTypes: () => sortDecorator(RouteTarifTypes),
  RouteTypes: () => sortDecorator(RouteTypes),
  RoutesData: () => sortDecorator(RoutesData),
  zoneroutetariffs: () => sortDecorator(zoneroutetariffs),
  zoneroute: () => sortDecorator(zoneroute),
  vt_routesdata: () => sortDecorator(RoutesData),
  Tariffs: () => sortDecorator(Tariffs),
  vt_section: () => sortDecorator(vt_section),
  addinfo_fieldstypes: () => sortDecorator(addinfo_fieldstypes),
  addinfo_fields: () => sortDecorator(addinfo_fields),
  action_history: () => sortDecorator(action_history),
  TransportCustomers: () => sortDecorator(TransportCustomers),
  TransportData: () => sortDecorator(TransportData),
  vt_transporttyps: () => sortDecorator(vt_transporttyps),
  // vt_security_rel_key_sam_view: () => sortDecorator(vt_security_rel_key_sam_view),
  deviceidhistory_reasons: () => sortDecorator(deviceidhistory_reasons),
  deviceidhistory: () => sortDecorator(deviceidhistory),
  VT_Contracts: () => sortDecorator(VT_Contracts),
  VT_Counterparties: () => sortDecorator(VT_Counterparties),
  VT_Extra_charges: () => sortDecorator(VT_Extra_charges),
  VT_Transfer: () => sortDecorator(VT_Transfer),
  VT_Zone: () => sortDecorator(VT_Zone),
  VT_bonuses: () => sortDecorator(VT_bonuses),
  vt_datagrammblacklistreasons: () =>
    sortDecorator(vt_datagrammblacklistreasons),
  ZoneTariffs: () => sortDecorator(ZoneTariffs),
  ZoneTariffsDetais: () => sortDecorator(ZoneTariffsDetais),
  arc_cardsdata: () => sortDecorator(arc_cardsdata),
  arc_routesdata: () => sortDecorator(arc_routesdata),
  blacklistdelta: () => sortDecorator(blacklistdelta),
  blacklistfull: () => sortDecorator(blacklistfull),
  blacklistshort: () => sortDecorator(blacklistshort),
  datagramm: () => sortDecorator(datagramm),
  history: () => sortDecorator(history),
  ordersdata: () => sortDecorator(ordersdata),
  transactionsvpbtest: () => sortDecorator(transactionsvpbtest),
  sodtranstest: () => sortDecorator(sodtranstest),
  protocol_bocot_ext: () => sortDecorator(protocol_bocot_ext),
  tarif: () => sortDecorator(tarif),
  lcl_closuretypes: () => sortDecorator(lcl_closuretypes),
  ticketinterval: () => sortDecorator(ticketinterval),
  ticketmony: () => sortDecorator(ticketmony),
  ticketunit: () => sortDecorator(ticketunit),
  virtualcards: () => sortDecorator(virtualcards),
  vt_cardsdata: () => sortDecorator(vt_cardsdata),
  RoutesByContract: () => sortDecorator(RoutesByContract),
  VT_RoutesByTransfer: () => sortDecorator(VT_RoutesByTransfer),
  VT_RoutesByZone: () => sortDecorator(VT_RoutesByZone),
  counterparties_statuses: () => sortDecorator(counterparties_statuses),
  vt_typesbycontract: () => sortDecorator(vt_typesbycontract),
  settings: () => sortDecorator(settings),
  tarifclass: () => sortDecorator(tarifclass),
  prioritydata: () => sortDecorator(prioritydata),
  transactionsvpb: () => sortDecorator(transactionsvpb),
  additional_messages: () => sortDecorator(additional_messages),
  // message_types: () => sortDecorator(message_types),
  system_users: () => sortDecorator(system_users),
  system_roles: () => sortDecorator(system_roles),
  counterparties_users_roles: () => sortDecorator(counterparties_users_roles),
  counterparties_users_dictionaries: () =>
    sortDecorator(counterparties_users_dictionaries),
  dictionaries_list: () => sortDecorator(dictionaries_list),
  vt_counterparties_carriers: () => sortDecorator(vt_counterparties_carriers),
  devicestatuses: () => sortDecorator(devicestatuses),
  table_permission_description: () =>
    sortDecorator(table_permission_description),
  table_permission_description_readonly: () =>
    sortDecorator(table_permission_description_readonly),
  zones: () => sortDecorator(zones),
  zone_stations: () => sortDecorator(zone_stations),
  zone_tariff: () => sortDecorator(zone_tariff),
  // vt_security_data_view: () => sortDecorator(vt_security_data_view),
  datagrammreasonsdata: () => sortDecorator(datagrammreasonsdata),
  vt_counterparties_realisators: () =>
    sortDecorator(vt_counterparties_realisators),
  issuance_areastatuses: () => sortDecorator(issuance_areastatuses),
  vt_security_data: () => sortDecorator(vt_security_data),
  device_types: () => sortDecorator(device_types),
  contract_types: () => sortDecorator(contract_types),
  contract_kinds: () => sortDecorator(contract_kinds),
  device_manufacturers: () => sortDecorator(device_manufacturers),
  exch_mutualpaymentstypes: () => sortDecorator(exch_mutualpaymentstypes),
  vt_security_algoritms: () => sortDecorator(vt_security_algoritms),
  devices_access_dictionary: () => sortDecorator(devices_access_dictionary),
  devices_access_statuses: () => sortDecorator(devices_access_statuses),
  counterpartyroles: () => sortDecorator(counterpartyroles),
  sodtrans: () => sortDecorator(sodtrans),
  controlvpb: () => sortDecorator(controlvpb),
  logdata: () => sortDecorator(logdata),
  vt_blacklist: () => sortDecorator(vt_blacklist),
  datagrammtypes: () => sortDecorator(datagrammtypes),
  carddatatypes: () => sortDecorator(carddatatypes),
  reparereseeb: () => sortDecorator(reparereseeb),
  vt_datagrammblacklist: () => sortDecorator(vt_datagrammblacklist),
  testvirtualticket: () => sortDecorator(testvirtualticket),
  access_data: () => sortDecorator(access_data),
  contracts_statuses: () => sortDecorator(contracts_statuses),
  vt_additional_agreement: () => sortDecorator(vt_additional_agreement),
  vt_errorcode: () => sortDecorator(vt_errorcode),
  vt_ticketstatus: () => sortDecorator(vt_ticketstatus),
  vt_security_keys: () => sortDecorator(vt_security_keys),
  vt_security_sam: () => sortDecorator(vt_security_sam),
  vt_security_samkeyentry: () => sortDecorator(vt_security_samkeyentry),
  vt_security_keycategories: () => sortDecorator(vt_security_keycategories),
  vt_security_samupdates: () => sortDecorator(vt_security_samupdates),
  vt_security_settings: () => sortDecorator(vt_security_settings),
};

const newTables = {};
export const preparedTables = {};

const createStructureTable =
  ({ tableCode, tableDescription }) =>
  () => ({
    tableCode: `structure_${tableCode}`,
    local: true,
    indexField: ['id'],
    viewField: 'name',
    tableDescription: [
      { key: 'id', name: 'ID', type: String },
      { key: 'name', name: 'Имя', type: String },
    ],
    tableContent: tableDescription.map((val) => ({
      id: val.key,
      name: val.name,
    })),
  });

Object.keys(tables).forEach((key) => {
  if (key.toLowerCase() !== 'system_roles') {
    tables[`structure_${key.toLowerCase()}`] = createStructureTable(
      tables[key]()
    );
  }
});

Object.keys(tables).forEach((key) => {
  if (key === 'system_roles') {
    newTables[key] = () => {
      const tbl = tables[key]();
      tbl.tableDescription = tbl.tableDescription(tables);
      return tbl;
    };
    preparedTables[key.toLowerCase()] = tables[key]();
    preparedTables[key].tableDescription =
      tables[key]().tableDescription(tables);
    return;
  }
  newTables[key.toLowerCase()] = tables[key];
  preparedTables[key.toLowerCase()] = tables[key]();
});

//
// const ANALYS = {};
//
// Object.keys(preparedTables).forEach(item => {
//     ANALYS[`${item}/${preparedTables[item].tableName}`] = preparedTables[item].tableDescription.map(row => ({
//         name: row.name,
//         key: row.key,
//         type: row.type ? row.type.name : '-',
//         relation: (row.relation || row.data_from) ?
//             ((row.relation && row.relation.table) || (row.data_from && row.data_from.table)) : '',
//     }))
// });
//
// // console.log(JSON.stringify(ANALYS));

// console.log(JSON.stringify(Object.entries(preparedTables).map(([key, table]) => ({
//    key,
//    name: table.tableName
// }))));

export default newTables;
