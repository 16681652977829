import { sort } from '@/constants';
import { vt_counterparties_join } from '@/joins';
import Store from '@/store/store';

export default {
  tableCode: 'transactionsvpb',
  tableForSelect: 'transactionsvpb_enriched',
  tableName: 'Транзакции ВПБ',
  folderName: 'reestr',
  indexField: ['datagrammid'],
  defaultOrder: {
    operationdatetime: sort.DESC,
  },
  importForceEnabled: true,
  readOnly: true,

  tableDescription: [
    vt_counterparties_join('idcarrier', 'Наименование контрагента-перевозчика'),
    {
      type: BigInt,
      key: 'vt_cardsdata_id',
      name: 'Идентификатор типа/вида ПБ',
      relation: {
        table: 'vt_cardsdata',
        join_rules: {
          vt_cardsdata_id: 'vt_cardsdata_id',
        },
      },
    },
    {
      type: BigInt,
      key: 'idts',
      name: 'Идентификатор ТС',
    },
    {
      type: BigInt,
      key: 'idroute',
      name: 'Идентификатор маршрута',
    },
    {
      type: BigInt,
      key: 'idtrip',
      name: 'Идентификатор рейса',
    },
    {
      type: BigInt,
      key: 'closuretype',
      name: 'Тип гашения',
      relation: {
        table: 'lcl_closuretypes',
        join_rules: {
          closuretype: 'id',
        },
      },
    },
    // {
    //     type: BigInt,
    //     name: 'Идентификатор виртуальной карты',
    //     key: 'cardid',
    //     // column_hidden: true,
    //     // skip_input: true,
    // },
    {
      type: String,
      key: 'datagrammid',
      name: 'Идентификатор датаграммы',
    },
    {
      type: BigInt,
      key: 'cardid',
      name: 'ID карты',
    },
    {
      type: String,
      key: 'balance',
      name: 'Баланс',
    },
    {
      type: String,
      name: 'Контактная информация пользователя МП/ЛКП',
      key: 'credentials',
      column_hidden: () => !Store.$store.getters.shallSeeCredentials, // +
    },
    {
      key: 'zone_start',
      type: BigInt,
      name: 'Зона (откуда)',
    },
    {
      key: 'zone_end',
      type: BigInt,
      name: 'Зона (куда)',
    },
    {
      type: Date,
      name: 'Дата время операции ',
      key: 'operationdatetime',
    },
    {
      type: Date,
      name: 'Время регистрации транзакции с ТС',
      key: 'tstransactionregdatetime',
    },
    {
      type: Date,
      name: 'Время регистрации транзакции с МП',
      key: 'mptransactionregdatetime',
    },
    {
      type: BigInt,
      name: 'Сумма списания ',
      key: 'units',
    },
  ],
};
