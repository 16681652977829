import * as R from 'ramda';
import axios from '@/utils/axios';
import { preparedTables } from '@/tables';

export const wrapPayload = (filters, table_name, out = true) => {
  const payload = { ...filters };

  const table = preparedTables[table_name];
  const description = table
    ? R.indexBy(R.prop('key'), table.tableDescription)
    : {};

  Object.keys(payload).forEach((key) => {
    const column = description[key];
    if (!column) return;

    if (column && column.skip_decorating) return;

    if (column && column.server_decorator) {
      payload[key] = column.server_decorator(payload[key], out);
      return;
    }

    if (payload[key] instanceof Date) {
      // console.log((new Date(payload[key].valueOf() + 3 * 60 * 60 * 1e3)).toJSON())

      payload[key] = new Date(
        payload[key].valueOf() + 3 * 60 * 60 * 1e3
      ).toJSON();
    }

    // console.log('WRAPPING CHECK', key, payload[key]);

    if (typeof payload[key] !== 'string') return;

    const l = payload[key].split('/');

    if (l.length === 1) return;
    if (!column.relation) return;

    const keyObj = R.invertObj(column.relation.join_rules);

    l.map((i) => i.split(':')).forEach(([key, value]) => {
      payload[keyObj[key]] = value;
    });
  });

  return payload;
};

export const fetchSourceForSelect = async (
  { commit },
  { table_name, primaryKey, viewField, table_storage, additionalFields }
) => {
  let result = [viewField];

  if (typeof viewField !== 'string') {
    const vf = new viewField().valueOf(true);

    result = Array.isArray(vf) ? vf : [vf];
  }

  const table = preparedTables[table_name];
  if (table.local) {
    commit('writeToStorage', {
      data: table.tableContent,
      table_name: table_storage || table_name,
    });
    commit('writeCountToStorage', {
      count: table.tableContent.length,
      table_name: table_storage || table_name,
    });
    return;
  }

  const {
    data: { data, count },
  } = await axios.post(
    `storage/${table.tableForFilter || table_name}/select_data`,
    { primaryKey, viewField: result, additionalFields }
  );

  commit('writeToStorage', { data, table_name: table_storage || table_name });
  commit('writeCountToStorage', {
    count,
    table_name: table_storage || table_name,
  });
};

export const fetchTable = async (
  { commit },
  { table_name, table_for_select, filters, ...params }
) => {
  const _filters = wrapPayload(filters, table_name);

  const {
    data: { data, count, softDeleteSupported },
  } = await axios.post(`storage/${table_for_select || table_name}/get`, {
    filters: _filters,
    ...params,
  });

  const result = data.map((item) => wrapPayload(item, table_name, false));
  // console.log(data, result);

  commit('writeToStorage', { data: result, table_name });
  commit('writeCountToStorage', { count, table_name });
  commit('writeSoftDeleteToStorage', { softDeleteSupported, table_name });

  return result;
};

export const removeItem = (_, { table_name, primary_key }) =>
  axios.post(`storage/${table_name}/delete_row`, primary_key);

export const addItem = (_, { table_name, payload, additionalFields }) =>
  axios.post(`storage/${table_name}/add_row`, {
    payload: wrapPayload(payload, table_name),
    additionalFields,
  });

export const editItem = (
  _,
  { table_name, primary_key, payload, additionalFields }
) =>
  axios.post(`storage/${table_name}/edit_row`, {
    payload: wrapPayload(payload, table_name),
    primary_key: wrapPayload(primary_key, table_name),
    additionalFields,
  });

export const fileOperations = async (
  _,
  { table_name, operation, filepath }
) => {
  axios.defaults.timeout = 5 * 60e3;

  const { data } = await axios.post(`storage/${table_name}/csv`, {
    operation,
    filepath,
  });

  return data;
  // axios.defaults.timeout = 10e3;
};

export const loadReport = async (_, { idcarrier, filepath, url }) => {
  axios.defaults.timeout = 5 * 60e3;

  await axios.post(url, {
    idcarrier,
    filepath,
  });
};
