import {sort} from "@/constants";

export default {
    tableCode: 'vt_security_settings',
    tableName: 'Системные параметры',
    indexField: ['settings_id'],
    folderName: 'key_security',
    defaultOrder: {
        settings_id: sort.ASC,
    },
    tableDescription: [

        {
            key: "settings_id",
            name: "ID",
            type: BigInt,
            relation: {
                table: 'vt_security_keys',
                join_rules: {
                    settings_id: 'key_description',
                }
            },
            skip_input: true,
        },
        {
            key: "fk_settings_avkpd_key_id",
            name: "Ключ, по которому определяется Актуальная версия ключа КПД",
            type: BigInt,
            relation: {
                table: 'vt_security_keys',
                join_rules: {
                    fk_settings_avkpd_key_id: 'key_description',
                }
            },
            skip_input: true,
        },
        {
            key: "fk_settings_avkpk_key_id",
            name: "Ключ, по которому определяется Актуальная версия ключа КПК",
            type: BigInt
        },
        {
            key: "settings_keyupdatedelay",
            name: "Задержка обновления ключа, час.",
            type: BigInt
        },
        {
            key: "settings_paramfreq",
            name: "Частота параметризации БО, час.",
            type: BigInt
        },
        {
            key: "settings_offlinetimer",
            name: "Период автономной работы БО, час.",
            type: BigInt
        },
        {
            key: "settings_date",
            name: "Дата-время установки параметров",
            type: Date
        },
        {
            key: "settings_offlineupdate_includeuid",
            name: "Признак добавления UID к криптограмме",
            type: "Boolean",
        },

    ],
};
