import { sort } from '@/constants';

export default {
  tableCode: 'tarif',
  tableName: 'Тарифы ВПБ',
  indexField: ['id'],
  defaultOrder: {
    begindate: sort.ASC,
  },
  position: 3,
  folderName: 'nsi',
  tableDescription: [
    {
      type: BigInt,
      key: 'id',
      name: 'id',
      skip_input: true,
      column_hidden: () => true,
    },
    {
      type: BigInt,
      key: 'vt_cardsdata_id',
      name: 'Название ВПБ',
      relation: {
        table: 'vt_cardsdata',
        join_rules: {
          vt_cardsdata_id: 'vt_cardsdata_id',
        },
      },
    },
    //// TODO: Тип ВПБ + Вид ВПБч>
    {
      type: BigInt,
      key: 'priceperone',
      name: 'Стоимость (руб.)',
      server_decorator: (value, out) => (out ? value * 100 : value / 100),
      // decorator: value => value / 100,
    },
    // {
    //     type: BigInt,
    //     key: 'blockedsumm',
    //     name: 'Сумма блокировки (коп.)',
    // },
    {
      key: 'minresoursecount',
      type: BigInt,
      name: 'Минимальное значение ресурса',
    },
    {
      key: 'resoursecount',
      type: BigInt,
      name: 'Максимальное значение ресурса',
    },
    // {
    //     type: BigInt,
    //     key: 'maxtripcount',
    //     name: 'Число поездок за сутки',
    // },
    {
      key: 'qrdatagrammlifetime',
      type: BigInt,
      name: 'Срок жизни QR-датаграммы (мин.)',
    },
    {
      type: BigInt,
      key: 'qrsubdatagramlifetime',
      name: 'Срок жизни QR-микродатаграммы (сек.)',
    },
    {
      type: BigInt,
      key: 'datagrammlifetime',
      name: 'Срок жизни NFC-датаграммы (мин.)',
    },

    {
      type: BigInt,
      key: 'maxdatagrammcount',
      name: 'Количество активных датаграмм',
    },
    {
      type: BigInt,
      key: 'maxqrdatagrammcount',
      name: 'Максимальное количество QR-датаграмм',
    },
    {
      key: 'begindate',
      name: 'Начало действия',
      type: Date,
      validator: (date, row) => new Date(row.enddate) < date,
    },
    {
      key: 'enddate',
      type: Date,
      name: 'Окончание действия',
      validator: (date, row) => new Date(row.begindate) > date,
    },
  ],
};
