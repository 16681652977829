<template lang="pug">
  universal-table(
    :table-code="tableCode"
    :table-name="tableName"
    :index-field="indexField"
    :table-description="tableDescription"
    :default-order="defaultOrder"
    :disable-operations="readOnly"
    :skip-add="wrappedSkipAdd"
    :view-field="viewField"
    :expand="expand"
  )
    template(v-slot:expanded_row="{ row }")
      ////span {{ JSON.stringify(row) }}
      //universal-table(
      //  :table-code="usersTableData.tableCode"
      //  :table-name="usersTableData.tableName"
      //  :index-field="usersTableData.indexField"
      //  :table-description="usersTableData.tableDescription"
      //  :default-order="usersTableData.defaultOrder"
      //  :disable-operations="usersTableData.readOnly"
      //  :skip-add="usersTableData.wrappedSkipAdd"
      //  :view-field="usersTableData.viewField"
      //  :expand="usersTableData.expand"
      //)
      system-users(
        style="height: auto !important; padding-left: 20px; padding-right: 20px; width: calc(100vw - 300px)"
        :filters-disabled="true"
        :import-export-disabled="true"
        :scroll-menu-disabled="true"
        background-color="white"
        add-button-name='Добавить пользователя'
        show-removed-button-name='Показать удаленных пользователей'
        :default-filter="{ counterparty_id: row.counterparty_id }"
      )


</template>
<script>
import UniversalTable from '@/pages/UniversalTable';
import tables from '@/tables';
import { mapGetters } from 'vuex';
import SystemUsers from '@/pages/SystemUsers';
import { mounted } from '@/pages/rolesHook';

export default {
  components: {
    SystemUsers,
    UniversalTable,
  },
  data() {
    const table = tables['vt_counterparties'];
    return table ? { ...table() } : {};
  },
  computed: {
    usersTableData() {
      const table = tables['system_users'];
      return table ? { ...table() } : {};
    },
    wrappedSkipAdd() {
      return this.skipAdd || false;
    },
    ...mapGetters(['roles']),
    readOnly() {
      try {
        const role = this.roles.find(
          (r) => r.table_code.toLowerCase() === this.tableCode.toLowerCase()
        );

        return role.write === false;
      } catch (e) {
        console.log('info', e, this.tableCode);
        return false;
      }
    },
  },
  mounted() {
    mounted(this);
  },
};
</script>
