<template lang="pug">
  el-aside(:class="{ scroll__behavior: true, width__auto: menuHidden }")
    //el-radio-group(v-model="isCollapse" style="margin-bottom: 20px;")
      el-radio-button(:label="false") expand
      el-radio-button(:label="true") collapse
    h5.name АС ВПБ
      span.version v1.1.10-{{ appVersion }}
    el-divider
    el-menu.el-menu-vertical-demo(
      :default-active="currentActive"
      unique-opened
      :collapse="menuHidden"
    )


      template(v-for="(item, index) in menu")
        el-submenu(:index="index.toString()" v-if="item.sub_menus && item.sub_menus.length"
          :class="{double_line: item.isDoubleLine}"
          :key="item.key"
          )
          //router-link(:to="item.key" slot="title" tag="div")
          div(slot="title")
            arm-icon(:name="item.icon")
            span(v-html="item.name")

          el-menu-item-group
            router-link(
              tag="div"
              v-for="(subItem, subIndex) in item.sub_menus"
              :to="subItem.url || subItem.key"
              :key="subItem.key"
            ): el-menu-item(:index="`${index}-${subIndex}`"
                :active="subItem.active"
                style='padding-left: 40px !important'
                :class="{double_line: subItem.isDoubleLine}"
                v-html="subItem.name"
                icon
              )

        router-link(
          tag="div"
          :to="item.url || item.key"
          v-else :key="item.key"
        ): el-menu-item(
          :index="index.toString()"
          :class="{double_line: item.isDoubleLine}"
        )
          arm-icon(:name="item.icon" )
          span(slot="title" v-html="item.name")

      el-divider
      strong(style="margin-left: 20px; padding-bottom: 20px; display: block") {{ user.login }} / {{ user.fio }}
      el-menu-item(v-on:click="logout" index="100000")
        arm-icon(name='close')
        span(slot="title") Выход

</template>

<script>
import * as R from 'ramda';
import ArmIcon from '@/components/ArmIcon';
import { mapState, mapGetters } from 'vuex';
import { preparedTables } from '@/tables';
import FoldersInfo from '@/FoldersInfo';

export default {
  name: 'ArmNavigation',
  components: { ArmIcon },
  data: () => ({}),
  computed: {
    ...mapGetters(['roles', 'user', 'appVersion']),
    menu() {
      const dictionaries = this.roles
        .filter((r) => r.table_code !== 'settings')
        .filter((r) => r.read || r.write)
        .map((r) => preparedTables[r.table_code]);

      const isSettingsEnabled = this.roles.find(
        (r) => r.table_code === 'settings' && (r.read || r.write)
      );

      // console.log(preparedTables, this.roles.map(r => ([
      //     preparedTables[r.table_code], r.table_name, r.table_code
      //   ]
      // )));

      const serviceKey = '____';

      const dictionariesGroupped = R.groupBy(
        R.pipe(R.prop('folderName'), R.defaultTo(serviceKey))
      )(dictionaries);

      const makeMenuItem = (table) => {
        if (table && table.tableName) {
          return {
            name: table.tableName,
            key: `/dictionary/${table.tableCode.toLowerCase()}`,
            icon: 's-management',
            isDoubleLine: !!table.isDoubleLine,
            position: table.position || 1e6,
          };
        }
      };

      const menu = [];

      // if (dictionariesGroupped[serviceKey]) {
      //   const item = {
      //     name: FoldersInfo[serviceKey].name,
      //     key: Math.random(),
      //     icon: FoldersInfo[serviceKey].icon || 's-management',
      //     sub_menus: R.pipe(
      //       R.map(makeMenuItem),
      //       R.sortBy(R.prop('position'))
      //     )(dictionariesGroupped[serviceKey]),
      //   };

      //   // item.sub_menus = item.sub_menus
      //   //     .map(sm => ({ ...sm, position: sm.position || 1e6 }));
      //   //
      //   // item.sub_menus.sort((a, b) => - b.position + a.position);

      //   menu.push(item);
      // }

      delete dictionariesGroupped[serviceKey];

      Object.keys(dictionariesGroupped).forEach((key) => {
        const item = {
          name: FoldersInfo[key].name,
          key,
          icon: FoldersInfo[key].icon || 's-management',
          sub_menus: R.pipe(
            R.map(makeMenuItem),
            R.sortBy(R.prop('position'))
          )(dictionariesGroupped[key]),
        };

        // item.sub_menus = item.sub_menus
        //     .map(sm => ({ ...sm, position: sm.position || 1e6 }));
        //
        // item.sub_menus.sort((a, b) => b.position - a.position);

        menu.unshift(item);
      });

      if (this.$store.getters.user.reports_general) {
        const findReports = menu.find((key) => key.key === 'reports');
        if (findReports) {
          findReports.sub_menus.push({
            name: 'Все отчеты',
            key: 'reports',
            url: '/reports-module',
            icon: 'document-copy',
            // sub_menus: [],
          });
        } else {
          menu.push({
            name: 'Отчеты',
            key: 'reports',
            url: '/reports-module',
            icon: 'document-copy',
            sub_menus: [],
          });
        }
      }

      if (isSettingsEnabled) {
        menu.push({
          name: 'Параметры ВПБ',
          key: 'settings',
          icon: 'setting',
          sub_menus: [],
        });
      }

      menu.forEach((row) => {
        // console.log('USUAL ', row);
        if (!FoldersInfo[row.key]) {
          FoldersInfo[row.key] = {
            position: 999,
          };
        }
      });
      menu.sort(
        (a, b) => FoldersInfo[a.key].position - FoldersInfo[b.key].position
      );

      return menu;
    },

    ...mapGetters(['role']),
    ...mapState(['menuHidden']),
    currentActive() {
      for (let i = 0; i < this.menu.length; i++) {
        const item = this.menu[i];
        if (item.key === this.$route.path) {
          return i.toString();
        }
        if (item.sub_menus && item.sub_menus.length) {
          for (let j = 0; j < item.sub_menus.length; j++) {
            const subItem = item.sub_menus[j];

            if (subItem && subItem.key === this.$route.path) {
              return `${i}-${j}`;
            }
          }
        }
      }
      return '0';
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style lang="stylus" scoped>

.width__auto
  width auto !important

.name
  //font-family 'Lobster', cursive
  padding-left 30px
  padding-top 20px
  font-size 1.5em
  margin -7px auto
  .version
    font-size: 14px;
    margin-left: 10px;
    color: lightblue;

.scroll__behavior
  height 100vh
  overflow-y scroll

//.el-menu-vertical-demo:not(.el-menu--collapse)
//  width: 200px;
//  min-height: 400px;

.double_line
  line-height 20px !important
  margin-top 10px !important
  margin-bottom 5px !important

.el-submenu.double_line
  line-height 28px !important
</style>

<style lang="stylus">
.el-menu-item-group__title
  display none

li.el-menu-item
  line-height 40px !important
  height 40px !important

.el-menu--collapse
  .el-submenu.double_line
    .el-submenu__title
      line-height 56px !important
      margin-top 10px

.el-submenu.double_line
  .el-submenu__title
    line-height 28px !important
    margin-top 10px
    padding 5px 0 !important
  span
    display inline-block
</style>
