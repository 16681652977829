import {sort} from "@/constants";

export default {
    tableCode: 'vt_security_samkeyentry',
    indexField: [
        'samkeyentry_id'
    ],
    defaultOrder: {
        samkeyentry_id: sort.ASC,
    },
    viewField: 'samkeyentry_keyno',
    tableDescription: [
        {
            key: 'samkeyentry_id',
            type: BigInt,
            name: 'ID',
        },
        {
            key: 'samkeyentry_keyno',
            type: String,
            name: 'Индекс ключевой ячейки',
        },
        {
            key: 'samkeyentry_dfaid',
            name: 'DF_AID',
            type: BigInt,
        },
        {
            key: 'samkeyentry_dfkeyno',
            name: 'DF_KeyNo',
            type: BigInt,
        },
        {
            key: 'samkeyentry_keynocek',
            name: 'KeyNoCEK',
            type: BigInt,
        },
        {
            key: 'samkeyentry_keyvcek',
            name: 'KeyVCEK',
            type: BigInt,
        },
        {
            key: 'samkeyentry_refnokuc',
            name: 'RefNoKUC',
            type: BigInt,
        },
        {
            key: 'Allow Dump Session Key',
            name: 'samkeyentry_dumpsession',
            type: BigInt,
        },
        {
            key: 'samkeyentry_keepiv',
            name: 'Keep IV',
            type: BigInt,
        },
        {
            key: 'fk_samkeyentry_keytype_id',
            name: 'Key Type',
            type: BigInt,
            relation: {
                table: 'vt_security_samkeyentry_keytypes',
                join_rules: {
                    'fk_samkeyentry_keytype_id': 'keytype_id',
                }
            },
        },
        {
            key: 'samkeyentry_authkey',
            name: 'Auth Key',
            type: BigInt,
        },
        {
            key: 'samkeyentry_disable',
            name: 'Disable Key Entry',
            type: BigInt,
        },
        {
            key: 'samkeyentry_lockkey',
            name: 'Lock Key',
            type: BigInt,
        },
        {
            key: 'samkeyentry_disablewrite',
            name: 'Disable Writing Key to a PICC',
            type: BigInt,
        },
        {
            key: 'samkeyentry_disabledecry',
            name: 'Disable Decryption',
            type: BigInt,
        },
        {
            key: 'samkeyentry_disableencry',
            name: 'Disable Encryption',
            type: BigInt,
        },
        {
            key: 'samkeyentry_disableverify',
            name: 'Disable Verify MAC',
            type: BigInt,
        },
        {
            key: 'samkeyentry_disablegeneratemac',
            name: 'Disable Generate MAC',
            type: BigInt,
        },
        {
            key: 'fk_samkeyentry_keyclass_id',
            name: 'Key Class',
            type: BigInt,
            relation: {
                table: 'vt_security_samkeyentry_keyclasses',
                join_rules: {
                    fk_samkeyentry_keyclass_id: 'keyclass_id'
                }
            }
        },
        {
            key: 'Allow Dump Secret Key',
            name: 'samkeyentry_dumpsecretkey',
            type: BigInt,
        },
        {
            key: 'samkeyentry_diversify',
            name: 'Restrict to Diversified Use',
            type: BigInt,
        },
        {
            key: 'fk_samkeyentry_update_id',
            name: 'ID ключевой ячейки для обновления',
            type: BigInt,
        },
    ]
}

/*


 */
