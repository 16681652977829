import {sort} from "@/constants";

export default {
    tableCode: 'vt_security_keycategories',
    indexField: [
        'keycategory_id'
    ],
    defaultOrder: {
        keycategory_id: sort.ASC,
    },
    viewField: 'keycategory_description',
    tableDescription: [
        {
            key: 'keycategory_description',
            type: String,
        },
        {
            key: 'keycategory_id',
            type: BigInt,
        }
    ]
}
