import { sort } from '@/constants';
import system_roles_fields from '@/tables/UsersDatabase/system_roles_fields';

export default {
  tableCode: 'system_roles',
  tableForSelect: 'system_roles_enriched',
  tableName: 'Группы пользователей',
  folderName: 'users_management',
  indexField: ['id'],
  defaultOrder: {
    id: sort.ASC,
  },
  validation: {
    remove: (row) => {
      if (+row.users_count > 0) {
        throw new Error('Для удаления в группе не должно быть пользователей!');
      }
    },
  },
  viewField: 'name',
  tableDescription: (tablesList) => {
    const tableDescription = system_roles_fields;

    const tableDescriptionNew = [];

    tableDescription.forEach((item) => {
      // console.log(item);
      if (
        !item.key ||
        !item.key.startsWith('tbl_') ||
        item.key.includes('system_roles')
      ) {
        tableDescriptionNew.push(item);
        return;
      }

      const table = Object.values(tablesList)
        .map((i) => i())
        .find((i) => i.tableCode === item.key.slice(4));

      // console.log(table, Object.values(tablesList), item.key, item.key.slice(4));
      if (!table || table.tableCode.includes('_structure')) return;

      tableDescriptionNew.push(item);
      tableDescriptionNew.push({
        key: `tbl_${item.key.slice(4)}_select_info`,
        name: `Доступ к полям таблицы "${item.name}"`,
        type: Array,
        column_hidden: () => true,
        input_hidden: (row) =>
          row[`tbl_${item.tableCode}`]?.toLowerCase() !== 'read',
        data_from: {
          table: `structure_${item.key.slice(4)}`,
        },
      });
    });
    // console.log('blablabla', tableDescriptionNew);
    return tableDescriptionNew;
  },
};
