export default (viewField, row, for_server = false) => {
  if (typeof viewField === 'string') {
    return row[viewField];
  }

  const constructor = new viewField(row);

  // console.log(constructor.valueOf(for_server));
  return constructor.valueOf(for_server);
};
