import { sort } from '@/constants';
// import moment from "moment-timezone";

export default {
  tableCode: 'action_history',
  tableName: 'История действий',
  folderName: 'users_management',
  indexField: [],
  defaultOrder: {
    date: sort.DESC,
  },
  readOnly: true,
  expand: true,
  tableDescription: [
    {
      type: BigInt,
      name: 'ID пользователя',
      key: 'user_id',
      relation: {
        table: 'system_users',
        join_rules: {
          user_id: 'id',
        },
      },
    },
    {
      name: 'IP',
      key: 'user_ip',
      type: String,
    },
    {
      name: 'Имя справочника',
      key: 'table_name',
      type: String,
    },
    {
      name: 'Тип операции',
      key: 'query_type',
      type: String,
    },
    {
      name: 'Тело операции',
      key: 'payload',
      type: String,
    },
    {
      name: 'Предыдущее значение',
      key: 'previous_data',
      type: String,
    },
    {
      name: 'Возникновение ошибки',
      key: 'is_error',
      type: Boolean,
    },
    {
      name: 'Текст ошибки',
      key: 'error_data',
      type: String,
    },
    {
      key: 'date',
      name: 'Дата',
      type: Date,
      // decorator: value => {
      //     return `decorated:${moment(value).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss')}`
      // }
    },
  ],
};
