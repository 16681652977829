import {sort} from "@/constants";

export default {
    tableCode: 'vt_security_keys',
    tableName: 'Подраздел «Защитные <br>последовательности ВПБ»"',
    isDoubleLine: true,
    readOnly: true,
    folderName: 'key_security',
    indexField: [
        'key_id',
    ],
    defaultOrder: {
        key_id: sort.ASC,
    },
    viewField: 'key_description',
    tableDescription: [
        {
            key: 'key_id',
            name: 'ID',
            type: BigInt,
        },
        {
            key: 'key_description',
            type: String,
            name: 'Описание'
        },
        {
            key: 'key_date_create',
            name: 'Дата создания ключа',
            type: Date,
        },
        {
            key: 'key_date_expiry',
            name: 'Дата окончания срока действия ключа',
            type: Date,
        },
        {
            key: 'key_active_flag',
            name: 'Статус активности ключа',
            type: Boolean,
        },
        {
            key: 'key_fingerprint',
            name: 'Цифровой отпечаток ключа',
            type: String,
        },
        {
            key: 'key_version',
            name: 'Версия ключа',
            type: String
        },
        {
            key: 'fk_key_keycategory_id',
            name: 'ID категории ключа (права доступа к функционалу)',
            type: BigInt,
            relation: {
                table: 'vt_security_keycategories',
                join_rules: {
                    fk_key_keycategory_id: 'keycategory_id'
                }
            }
        },
        {
            key: 'fk_key_update_key_id',
            name: 'Ссылка на обновляемый ключ',
            type: String,
        },
    ]
}

/*




vt_security_keys. + если NULL, отображается прочерк.
иначе – отображается строка «Требуется обновление на
ключ
ключ с ID: » [+ значение поля БД as is] + цветовая
индикация красным
В случае наличия данных ячейка должна быть кликабельной с
переходом на запись с этим id (vt_security_keys.key_id) и
цветовой подсветкой фона, ана
 */
