export const getTable = (state) => (table) => state.storage[table];

export const getCount = (state) => (table) => state.count[table];

export const getTableRow =
  (state) =>
  (table, { search, values }) => {
    if (state.storage[table]) {
      return state.storage[table].find((element) =>
        search.every((k) => element[k].toString() === values[k].toString())
      );
    }
    return undefined;
  };

export const getTableRows =
  (state) =>
  (table, { search, values }) => {
    if (state.storage[table]) {
      return state.storage[table].filter((element) =>
        search.every((k) => element[k].toString() === values[k].toString())
      );
    }
    return undefined;
  };

export const getSoftDeleteSupport = (state) => (table) =>
  state.softDeleteSupport[table];
